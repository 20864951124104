import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Rawalpindi from '../../Assets/CitiesChapters/rawalpindiii.jpg'
import Lahore from '../../Assets/CitiesChapters/Lahoree.jpg'
import Quetta from '../../Assets/CitiesChapters/Quettaaa.jpg'
import Bahawalpur from '../../Assets/CitiesChapters/Bahawalpurrr.jpg'
import Gujranwala from '../../Assets/CitiesChapters/Gujranwalaa.jpg'
import Karachi from '../../Assets/CitiesChapters/Karachi 1.jpg'
import Mehran from '../../Assets/CitiesChapters/Innovista Mehran.jpeg.jpg'
import Multan from '../../Assets/CitiesChapters/Multann.jpg'
import Peshawar from '../../Assets/CitiesChapters/Peshawarr.jpg'
import Rawat from '../../Assets/CitiesChapters/Rawatt.jpg'

const ChapterDetail = () => {

    const [videoWidth, setVideoWidth] = useState('100%');
    const [showFullVideo, setShowFullVideo] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > window.innerHeight * 0.3) {
                setVideoWidth('70%');
                setShowFullVideo(false);
            } else {
                setVideoWidth('100%');
                setShowFullVideo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    type ChapterParams = {
        id: string;
    };

    const chapterData = {
        '01': {
            name: 'Bahawalpur',
            image: Bahawalpur,
            city: 'Innovista Cholistan',
            description: 'Innovista Cholistan is a state-of-the-art coworking space that has taken root in the serene landscapes of DHA Bahawalpur. It’s a sanctuary for freelancers, entrepreneurs, and businesses, offering fully equipped workspaces that cater to the modern professional’s every need. With top-notch facilities like free 5G WiFi, a conference hall, and a game zone, it’s designed to foster skill development and collaboration in a supportive environment. The space is more than just an office; it’s a community where creative minds converge to innovate and excel. As part of the Innovista network, Cholistan chapter stands out for its commitment to reversing the migration of human resources and capital by providing a conducive atmosphere for growth. Members can choose from various membership packages, including the FL Chair suitable for freelancers, the Double POD for small businesses, and the Studio Office for larger teams. Each package offers 24/7 access, free WiFi, and all-area access, ensuring that every member has the flexibility and resources they need to succeed.'
        },
        '02': {
            name: 'Gujranwala',
            image: Gujranwala,
            city: 'Innovista Rachna',
            description: 'Innovista Rachna is a vibrant coworking space nestled in the heart of DHA Gujranwala, a city known for its rich cultural heritage and industrial prowess. This modern facility is designed to cater to the diverse needs of freelancers, startups, and businesses, providing them with a dynamic environment that promotes collaboration and innovation. With amenities such as high-speed 5G WiFi, conference halls, and a variety of office equipment, Innovista Rachna is more than just a shared office space; it’s a community hub where creative minds come together to share ideas and drive professional growth. Reflecting the entrepreneurial spirit of Gujranwala, Innovista Rachna offers a range of membership packages to suit different professional needs, from individual freelancers to small businesses and larger teams.The coworking space boasts top- notch facilities including a kitchen, car parking, game zone, and even lockers for personal storage.It’s a place where work meets play, providing a balanced atmosphere that’s conducive to both productivity and relaxation.'
        },
        '03': {
            name: 'Karachi',
            image: Karachi,
            city: 'Innovista Indus',
            description: 'Innovista Indus is a state-of-the-art coworking space situated in the dynamic DHA Karachi, designed to be a hub for Pakistan’s tech scene. It’s a place where freelancers, startups, and businesses can come together to share ideas and work on innovative projects. The space is fully equipped with modern amenities such as free 5G WiFi, conference halls, and office equipment, making it an ideal location for skill development and professional networking. Innovista Indus is part of a larger initiative to boost the tech sector in Pakistan, providing a special place for tech experts like coders, designers, and makers to collaborate and create. The coworking space is not just about providing a desk or an office; it’s about creating a community where members can find support and inspiration. With various membership packages available, Innovista Indus caters to individual freelancers as well as small and large teams, offering 24/7 access, cafeterias, and all-area access to ensure maximum productivity and flexibility. The recent collaboration between DHA Karachi Innovista and Indus IT Feast signifies a significant step forward in fostering a vibrant tech ecosystem, attracting investment, and generating employment opportunities in the region.'
        },
        '04': {
            name: 'Lahore',
            image: Lahore,
            city: 'Innovista Ravi',
            description: 'Innovista Ravi is a premier coworking space located in the thriving DHA Phase 8 Lahore Business Hub, offering a dynamic and collaborative work environment for startups, companies, and individuals. It’s a place where professionals can connect and thrive, thanks to a perfect blend of professional workspace options and a vibrant community. The facility boasts prime location advantages, being situated in the heart of Lahore’s bustling business district, providing easy access to the city’s commercial centers. Innovista Ravi is not just about the space; it’s about the unparalleled support it offers, including business consultations and networking events, ensuring that every member has the resources they need to succeed. The coworking space is designed to meet international standards of commercial architecture, reflecting DHA Lahore’s commitment to setting trends in commercial project development.Innovista Ravi features flexible workspace solutions, from private offices to co- working spaces, catering to a diverse range of professional needs.Members enjoy top- notch amenities such as high-speed Wi - Fi, meeting rooms, and comfortable workstations.The development of this project is a testament to DHA’s dedication to providing a professional corporate ambiance and transcending business borders by offering a serene, secure, and conducive environment.'
        },
        '05': {
            name: 'Karachi',
            image: Mehran,
            city: 'Innovista Mehran',
            description: 'Description of Mehran'
        },
        '06': {
            name: 'Multan',
            image: Multan,
            city: 'Innovista Chenab',
            description: 'Innovista Chenab is a beacon of modernity and collaboration, located in the prestigious DHA Multan. It’s a coworking space that not only provides a professional setting for freelancers and businesses but also serves as a cradle for skill development and entrepreneurial success. With its fully equipped facilities, Innovista Chenab offers free 5G WiFi, a conference hall, kitchen, car parking, game zone, office equipment, and even lockers for personal storage. It’s a place where creative minds find their sanctuary amidst the hustle and bustle of Pakistan’s growing cities, fostering a community that’s dedicated to innovation and professional excellence. The coworking space is part of a nationwide network, Innovista, which is committed to reversing the migration of human resources and capital by providing state- of - the - art workspaces across Pakistan.Innovista Chenab, with its all- area access and flexible membership packages, caters to a diverse clientele, from individual freelancers to small businesses and larger teams.Whether it’s a single chair for a freelancer or a studio office for a team, Innovista Chenab ensures 24 / 7 access and a supportive environment that’s conducive to growth and productivity.'
        },
        '07': {
            name: 'Peshawar',
            image: Peshawar,
            city: 'Innovista Khyber',
            description: 'Innovista Khyber is a cutting-edge coworking space situated in the bustling Prism Mini Mart of DHA Peshawar, Khyber Pakhtunkhwa. Launched with the vision to foster innovation and collaboration among modern entrepreneurs, Innovista Khyber offers a professional environment equipped with state-of-the-art amenities. The facility stands out for its dedicated spaces tailored specifically for female entrepreneurs, highlighting its commitment to inclusivity and empowerment within the entrepreneurial ecosystem. With high-speed internet connectivity and reliable 24-hour power backup, it ensures that freelancers, startups, and established businesses can operate without interruptions. The coworking space is part of DHA Peshawar’s initiative to provide innovative neighborhood concepts and a high standard of living to its residents.Since its inception, Innovista Khyber has become operational and is contributing to reversing the migration of human resources and capital from other regions to Peshawar/ KP.Its strategic location at the hub of communication networks like the Northern Bypass and Ring Road, with direct access to University Road, makes it an extremely attractive site for professionals seeking a dynamic and supportive workspace.'
        },
        '08': {
            name: 'Quetta',
            image: Quetta,
            city: 'Innovista Chaghi',
            description: 'Quetta’s entrepreneurial spirit has received a significant boost with the opening of InnoVista Chaghi at Hanna Mall 2, DHA Quetta. This new chapter for InnoVista heralds a fresh wave of innovation and opportunity for the city’s business community, a new beacon for coworking spaces in Pakistan. This momentous event, held at the prestigious Hanna Mall 2, marks a significant expansion for InnoVista, which has been at the forefront of providing innovative work environments for freelancers, startups, and professionals. The launch of InnoVista Chaghi is a bold step towards reshaping the traditional office setting into a more dynamic and collaborative space.'
        },
        '09': {
            name: 'Rawalpindi',
            image: Rawalpindi, city: 'Innovista Rawal',
            description: 'The entrepreneurial landscape of Islamabad has been enriched with the inauguration of InnoVista Rawal at Defense Avenue Mall DHA-1, a new beacon for coworking spaces in Pakistan. This momentous event, held at the prestigious Defense Avenue Mall, marks a significant expansion for InnoVista, which has been at the forefront of providing innovative work environments for freelancers, startups, and professionals. The launch of InnoVista Rawal is a bold step towards reshaping the traditional office setting into a more dynamic and collaborative space.'
        },
        '10': {
            name: 'Rawat',
            image: Rawat, city: 'Innovista Rawat',
            description: 'Innovista Rawat stands as a modern coworking space located in the strategic area of Rawat, Islamabad, offering a professional sanctuary for freelancers, startups, and businesses. This facility is part of the Innovista network, which spans across Pakistan, providing fully equipped workspaces that are designed to foster skill development and entrepreneurial success. With amenities such as free 5G WiFi, conference halls, and a variety of office equipment, Innovista Rawat is more than just a shared office—it’s a community where creative minds can collaborate, learn, and grow in a supportive environment.The coworking space offers a range of membership packages to accommodate the needs of diverse professionals. Whether it’s the FL Chair suitable for individual freelancers, the Double POD for small businesses, or the Studio Office for larger teams, Innovista Rawat ensures 24/7 access, free WiFi, and all-area access. It’s a place where members can work with flexibility and comfort, surrounded by like-minded individuals who are all striving for professional excellence.'
        },
    };

    const { id } = useParams<ChapterParams>();

    if (!id) {
        return <Typography variant="h4">Invalid Chapter</Typography>;
    }

    const chapter = chapterData[id as keyof typeof chapterData];

    if (!chapter) {
        return <Typography variant="h4">Chapter not found</Typography>;
    }
    return (
        <>
            <div
                className={`relative flex flex-col items-center justify-center transition-all duration-500`}
                style={{ width: videoWidth, margin: '0 auto 10% auto' }}
            >
                <img src={chapter.image} className='absolute z-[-1] rounded-[10px] bg-image' />
                <div className='flex flex-col items-center justify-center p-[15%] gap-5 heading-outer-section'>
                    <Typography className='heading-text' sx={{
                        fontFamily: "Poppins",
                        fontSize: '120px',
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        lineHeight: '105px',
                        letterSpacing: '-5px',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}>
                        {chapter.name}
                    </Typography>
                </div>
            </div>

            <div className='flex flex-col items-center justify-center px-[10%] gap-10 detail-section'>
                <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: '48px',
                    fontWeight: '600',
                    letterSpacing: '-0.5px',
                    color: '#FFF',
                    textAlign: 'center',
                    textTransform: 'uppercase'
                }} className='detail-heading'>
                    ABOUT {chapter.city}
                </Typography>
                <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: '18px',
                    fontWeight: '400',
                    letterSpacing: '-0.5px',
                    color: '#FFF',
                    textAlign: 'center'
                }} className='detail-para'>
                    {chapter.description}
                </Typography>
            </div>

        </>
    )
}

export default ChapterDetail
