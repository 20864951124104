// ChatIcon.js
import React from 'react';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import './ChatIcon.scss';
import { WhatsApp } from '@mui/icons-material';

const ChatIcon = () => (
    <a href="https://wa.me/+923116927284" target='_BLANK' className="chat-icon">
        <WhatsApp style={{ fontSize: 28, color: 'white' }} />
    </a>
);

export default ChatIcon;
