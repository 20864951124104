import React from 'react'
import './Footer.scss'
import logoWhite from '../../Assets/InnovistaRaviLogoWhite.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { Button, Typography } from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Phone } from '@mui/icons-material';

const Footer = () => {
    return (
        <>
            <div className='flex flex-col px-[10%] py-[5%] gap-5 mt-[5%] footer-main'>
                <div className='flex items-center justify-between footer-header'>
                    <div className='w-full logo-div'>
                        <img src={logoWhite} alt="" className='max-w-[200px]' />
                    </div>
                    <div className='flex items-center gap-4 text-white'>
                        <a href="https://www.linkedin.com/company/innovistaofficial/" target='_BLANK'><LinkedInIcon /></a>
                        <a href="https://www.instagram.com/innovistaravi?igsh=MWVtNGNocnRhcjlocA==" target='_BLANK'><InstagramIcon /></a>
                        <a href="https://www.facebook.com/profile.php?id=61561146967668&mibextid=ZbWKwL" target='_BLANK'><FacebookIcon /></a>
                        <a href="https://www.facebook.com/profile.php?id=61561146967668&mibextid=ZbWKwL" target='_BLANK'>< WhatsAppIcon /></a>

                    </div>
                </div>
                <div className='flex items-center justify-between content-footer'>
                    <div>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontSize: '20px',
                            color: '#fff'
                        }} className='tagline-footer'>
                            Inspiring Spaces for Inspiring Minds
                        </Typography>
                    </div>

                    <div className='flex flex-col items-end gap-3'>
                        <Button
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                color: '#fff'
                            }}
                            className='tagline-footer'
                            href='https://wa.me/+923108147284'
                            startIcon={<Phone />}>
                            +92 310 8147284
                        </Button>
                        <Button
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                color: '#fff'
                            }}
                            className='tagline-footer'
                            href='https://wa.me/+923133337284'
                            startIcon={<WhatsAppIcon />}>
                            +92 313 3337284
                        </Button>

                    </div>
                </div>
                <div className='h-[1px] w-full bg-white' />
                <div className='flex items-center justify-between'>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        color: '#fff'
                    }}>@InnovistaRavi</Typography>

                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        color: '#fff'
                    }}>2024</Typography>

                </div>
            </div>
        </>
    )
}

export default Footer
