import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './Pricing.scss';
import SliderMain from '../../Components/Slider/SliderMain';
import inhouseCafe from '../../Assets/Services/CoreServices/inhouse-cafe.png'
import phoneBooth from '../../Assets/Services/CoreServices/phonebooth.png'
import securedEntry from '../../Assets/Services/CoreServices/secured-entry.png'
import highSpeed from '../../Assets/Services/CoreServices/high-speed.png'
import meetingPods from '../../Assets/Services/CoreServices/meeting-pods.png'
import mailHandling from '../../Assets/Services/CoreServices/mail-handling.png'
import teaCoffee from '../../Assets/Services/CoreServices/tea-coffee.png'
import powerBackup from '../../Assets/Services/CoreServices/power-backup.png'

import hotDesks from '../../Assets/Services/Hot Desk.jpg'
import dedicatedDesks from '../../Assets/Services/Dedicated Desk.jpg'
import podForThree from '../../Assets/Services/Pods of three.jpeg'
import individualPods from '../../Assets/Services/Individual Pods.jpg'
import virtualOffice from '../../Assets/Services/Virtual Office.jpg'
import executiveOffice from '../../Assets/Services/Executive Office.jpg'
import smallOffice from '../../Assets/Services/Small Office.jpg'
import teamOffice from '../../Assets/Services/Team Office.jpg'
import huddleRoom from '../../Assets/Services/Huddle room.jpg'
import meetingRoom from '../../Assets/Services/Meeting Room.jpg'
import boardRoom from '../../Assets/Services/Board Room.jpg'
import valueAddedServices from '../../Assets/InnovistaContent8-min.png'

const PricingPlans = () => {
    const [videoWidth, setVideoWidth] = useState('100%');
    const [showFullVideo, setShowFullVideo] = useState(true);
    const [selectedTab, setSelectedTab] = useState<'Standard Packages' | 'Private Rooms' | 'Value Added Services'>('Standard Packages');


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > window.innerHeight * 0.3) {
                setVideoWidth('70%');
                setShowFullVideo(false);
            } else {
                setVideoWidth('100%');
                setShowFullVideo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);



    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        // Clear previous refs and observe the current ones
        cardRefs.current.forEach((ref) => ref && observer.observe(ref));

        return () => observer.disconnect(); // Disconnect observer on cleanup
    }, [selectedTab]);

    const plansData = {
        'Standard Packages': [
            {
                name: 'Hot Desks',
                image: hotDesks, // Replace with appropriate image
                features: [
                    'Flexible Seating in the Co-working Space or Common Areas',
                    'Includes Sofa, Stool, and Access to Shared Areas',
                    'Includes Core Services',
                    'Meeting Room Quota: Not Included',
                ],
                buttonText: 'View More',
            },
            {
                name: 'Dedicated Desks',
                image: dedicatedDesks, // Replace with appropriate image
                features: [
                    'Designated and Numbered Desk in the Co-working Space',
                    'Includes Core Services',
                    'Meeting Room Quota: Not Included',
                ],
                buttonText: 'View More',
            },
            {
                name: 'Pod for Three',
                image: podForThree, // Replace with appropriate image
                features: [
                    'Fixed and Numbered Open Pod for 3-person Teams',
                    'Includes Core Services',
                    'Meeting Room Quota: 5 Hours/Month in a Mid-Sized Meeting Room',
                    'Meeting Room Credits (1 Credit = 1 PKR): 15,000'
                ],
                buttonText: 'View More',
            },
            {
                name: 'Individual Pods',
                image: individualPods, // Replace with appropriate image
                features: [
                    'Single-Person Pod in the Co-working Space',
                    'Includes Core Services',
                    'Meeting Room Quota: 5 Hours/Month in a Mid-Sized Meeting Room',
                    'Meeting Room Credits (1 Credit = 1 PKR): 15,000'
                ],
                buttonText: 'View More',
            },
            {
                name: 'Virtual Office',
                image: virtualOffice, // Replace with appropriate image
                features: [
                    'Virtual Office Address',
                    '3 Days/Month Access to Hot Desk & Registered Office Mailing Address',
                    'Meeting Room Quota: Not Included'
                ],
                buttonText: 'View More',
            },
        ],
        'Private Rooms': [
            {
                name: 'Executive Offices',
                image: executiveOffice, // Replace with appropriate image
                features: [
                    'Exclusive Office Space for Top-Level Executives',
                    'Includes Core Services',
                    'Meeting Room Quota: 12 Hours/Month in a Mid-Sized Meeting Room',
                    'Meeting Room Credits (1 Credit = 1 PKR): 20,000'
                ],
                buttonText: 'View More',
            },
            {
                name: 'Small Offices',
                image: smallOffice, // Replace with appropriate image
                features: [
                    'Compact Private Office for 1-3 People',
                    'Includes Core Services',
                    'Meeting Room Qouta: Not Included',
                ],
                buttonText: 'View More',
            },
            {
                name: 'Team Offices',
                image: teamOffice, // Replace with appropriate image
                features: [
                    'Private Offices for 4+ People, Ideal for Startups and Enterprises',
                    'Includes Core Services',
                    'Meeting Room Quota: 0.75 Hours/Seat/Month in a Mid-Sized Meeting Room',
                    'Meeting Room Credits (1 Credit = 1 PKR): 2,500 per Seat',
                ],
                buttonText: 'View More',
            },
        ],
        'Value Added Services': [
            {
                name: 'Huddle Room',
                image: huddleRoom, // Replace with appropriate image
                features: [
                    'Hourly: 1000/Hour',
                    'Day: 5000/Day',
                ],
                buttonText: 'View More',
            },
            {
                name: 'Meeting Room',
                image: meetingRoom, // Replace with appropriate image
                features: [
                    'Hourly: 3850/Hour',
                    'Day: 12500/Day',
                ],
                buttonText: 'View More',
            },
            {
                name: 'Board Room',
                image: boardRoom, // Replace with appropriate image
                features: [
                    'Hourly: 5830/Hour',
                    'Day: 25000/Day',
                ],
                buttonText: 'View More',
            },
            {
                name: 'Value Added Services',
                image: valueAddedServices, // Replace with appropriate image
                features: [
                    'Hall Lockers: 3000/Month',
                    'Glass Board',
                    'Company Branding: Cost + 50%',
                    'Podcast Studio',
                    'Parking Spots',
                    'Event Spaces'
                ],
                // buttonText: 'View More',
            },
        ],
    };


    const coreServices = [
        inhouseCafe,
        phoneBooth,
        securedEntry,
        highSpeed,
        meetingPods,
        mailHandling,
        teaCoffee,
        powerBackup
    ]

    const settingsService = {
        dots: false,
        speed: 300,
        slidesToShow: 5,
        centerMode: true,
        centerPadding: "0px",
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <SliderMain images={coreServices ?? []} settings={settingsService} />

            <div className="pricing-section-services">
                <div className="flex items-center justify-between w-full articles-heading mb-[5%]">
                    <Box className="flex flex-col justify-between items-start">
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            fontSize: '15px',
                            color: '#fff',
                            letterSpacing: '4px'
                        }}>
                            PRICING
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            textAlign: 'left',
                            fontWeight: '600',
                            fontSize: '56px',
                            color: '#fff',
                            lineHeight: '1.2em'
                        }} className="main-header">
                            Flexible Packages For Your Business Plan
                        </Typography>
                    </Box>
                </div>

                <Box className="mb-20 text-center">
                    <ButtonGroup variant="contained">
                        {(["Standard Packages", "Private Rooms", "Value Added Services"] as const).map((tab) => (
                            <Button
                                key={tab}
                                onClick={() => setSelectedTab(tab)}
                                color={selectedTab === tab ? "primary" : "inherit"}
                                variant='contained'
                                sx={{
                                    backgroundColor: selectedTab === tab ? "#F02D27" : "#2A2A2A",
                                    borderRight: 'none !important',
                                    color: '#fff',
                                    "&:hover": {
                                        backgroundColor: selectedTab === tab ? "#F02D27" : '#3c3c3c',
                                    },
                                    padding: '10px 20px',
                                    fontWeight: '600',
                                    fontFamily: 'Poppins'
                                }}
                            >
                                {tab}
                            </Button>
                        ))}
                    </ButtonGroup>

                </Box>

                <div className="pricing-cards-services">
                    {plansData[selectedTab].map((plan, index) => (
                        <div className="pricing-card" key={index} ref={(el) => (cardRefs.current[index] = el)}>
                            <div>
                                {/* Render the image */}
                                <img src={plan.image} alt={`${plan.name} image`} className="plan-image" />
                                <Typography variant="h5" className="plan-name">
                                    {plan.name}
                                </Typography>
                                <ul className="features-list">
                                    {plan.features.map((feature, idx) => (
                                        <li key={idx} className="feature-item">
                                            <i className="check-icon">✔</i>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex items-center">
                                {plan.name !== "Value Added Services" && (
                                    <Button variant="contained" className="contact-button">
                                        <a href="/Services" target="_blank" rel="noopener noreferrer">{plan.buttonText}</a>
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PricingPlans;
